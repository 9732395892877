import { getLocalStorageObject } from '@/helpers/localStorage';
import { vueWorkrampLogin } from '@/api/vue';
import { redirectExclusions } from '@/helpers/constants/redirectExclusions';
import { shareableInsights } from '@/helpers/constants/shareableInsights';
import { useHome } from '@/stores/home/home';
import { getParentRoute } from '@/helpers/routing';
import { beaconBlacklist } from '@/helpers/constants/beaconBlacklist';

export const addRouterHooks = (router, {
  authDataStore, gateStore, allowMobileStore, isInternetExplorer, isMobileBrowser, routeStore,
}) => {
  router.beforeEach(async (to, from) => {
    const fetchAuthData = to?.name && to.name !== from.name;

    /* Redirects user if they're using a deprecated version of IE */
    if (isInternetExplorer && to.name !== 'ie-deprecated') {
      return { name: 'ie-deprecated' };
    }

    /* Redirects user if they're viewing the app on mobile browser */
    if (
      isMobileBrowser
      && to.name !== 'login'
      && to.name !== 'mobile-not-supported'
      && (window.innerWidth <= 600 || window.innerHeight <= 900)
      && !allowMobileStore.mobileAllowed
    ) {
      return { name: 'mobile-not-supported' };
    }

    /*
      Writes key/value to local storage for redirecting user
      to datapeople-anywhere page if they're coming from Chrome extension
    */
    if (to.query.from === 'datapeople-anywhere') {
      localStorage?.setItem('datapeopleAnywhereRedirect', JSON.stringify({ name: 'datapeople-anywhere-login-success' }));
      // eslint-disable-next-line no-param-reassign
      delete to.query.from;
      return to;
    }

    if (to.query.from === 'workramp') {
      localStorage?.setItem('workrampRedirect', JSON.stringify(true));
      // eslint-disable-next-line no-param-reassign
      delete to.query.from;
    }

    if (['editor-view', 'editor-view-reload'].includes(to.name)) {
      if (to.query.key) {
        window?.sessionStorage?.setItem('shareKey', to.query.key);
        window?.sessionStorage?.setItem('shareJobId', to.params.id);
        // eslint-disable-next-line no-param-reassign
        delete to.query.key;
        return to;
      }
    } else {
      window?.sessionStorage?.removeItem('shareKey');
      window?.sessionStorage?.removeItem('shareJobId');
    }

    if (shareableInsights.includes(to.name)) {
      if (to.query.key) {
        window?.sessionStorage?.setItem('reportingShareKey', to.query.key);
        // eslint-disable-next-line no-param-reassign
        delete to.query.key;
        return to;
      }
    } else {
      window?.sessionStorage?.removeItem('reportingShareKey');
    }

    if (to.meta.authStateRequired && to.meta.authStateRequired === 'either') {
      return true;
    } if (to.meta.authStateRequired && to.meta.authStateRequired === 'unauthenticated') {
      if (fetchAuthData) await authDataStore.fetchAuthData();

      if (authDataStore.authData.isLoggedIn === true) {
        return { name: 'root' };
      }
      return true;
    }
    // authenticated route
    if (fetchAuthData) await authDataStore.fetchAuthData();

    if (authDataStore.authData.isLoggedIn === false) {
      // Creates router object manually to avoid circular json structure
      localStorage?.setItem('redirectRoute', JSON.stringify({
        name: to.name,
        query: to.query,
        params: to.params,
      }));

      return { name: 'login' };
    }

    return true;
  });

  router.beforeEach(async (to, from) => {
    const fetchAuthData = to?.name && to.name !== from.name;

    if (to.meta.superuserRequired) {
      if (fetchAuthData) await authDataStore.fetchAuthData();
      if (authDataStore.authData.isSuperuser === false) {
        return { name: 'root' };
      }
      return true;
    }
    return true;
  });

  router.beforeEach(async (to) => {
    if (!authDataStore.authData.isLoggedIn) {
      return true;
    }

    const redirectRouteJson = window?.sessionStorage?.getItem('redirectRoute');
    let redirectRoute = null;

    if (redirectRouteJson) {
      window?.sessionStorage?.removeItem('redirectRoute');
      try {
        redirectRoute = JSON.parse(redirectRouteJson);
      } catch (e) {
        // ignore JSON.parse error
      }
    }

    /* Redirects:
        1. LocalStorage redirect to handle sign up flow
        2. Datapeople Anywhere redirect to handle Chrome extension
        3. Workramp redirect to handle Workramp instant auth coming from Workramp site
    */

    const localStorageRedirect = getLocalStorageObject({ key: 'redirectRoute', remove: true });
    const isDatapeopleAnywhereRedirect = getLocalStorageObject({ key: 'datapeopleAnywhereRedirect', remove: false });
    const isWorkrampRedirect = getLocalStorageObject({ key: 'workrampRedirect', remove: false });

    if (isWorkrampRedirect) {
      if (redirectExclusions.includes(to.name)) {
        /* break redirect chain and proceed with nav */
        getLocalStorageObject({ key: 'workrampRedirect', remove: true });
      } else {
        /* redirect to Workramp */
        const { loginUrl } = await vueWorkrampLogin();
        window.location.href = `https://university.datapeople.io${loginUrl}`;
        getLocalStorageObject({ key: 'workrampRedirect', remove: true });
        return true;
      }
    }

    if (redirectRoute) {
      return redirectRoute;
    } if (localStorageRedirect) {
      return localStorageRedirect;
    } if (isDatapeopleAnywhereRedirect) {
      /* First check for page exclusions where redirect should not occur */
      if (redirectExclusions.includes(to.name)) {
        return true;
        /* Without this else if check, the router loops infinitely */
      } if (to.name !== 'datapeople-anywhere-login-success') {
        return isDatapeopleAnywhereRedirect;
      }
      return true;
    }
    return true;
  });

  router.beforeEach(async (to) => {
    routeStore.updateRoute(to);
    return true;
  });

  router.beforeEach(async (to) => {
    if (!gateStore.errorPageRoute) {
      return true;
    }

    if (gateStore.errorPageRoute === to.name) {
      return true;
    }
    return { name: gateStore.errorPageRoute };
  });

  router.afterEach((to, from) => {
    // Came from a page that is not blacklisted and going to a page that is blacklisted
    if (
      !beaconBlacklist.includes(from.name)
      && beaconBlacklist.includes(to.name)
      && window.Beacon
    ) {
      window.Beacon('destroy');
    // Came from a page that is blacklisted and going to a page that is not blacklisted
    } else if (
      beaconBlacklist.includes(from.name)
      && !beaconBlacklist.includes(to.name)
      && window.Beacon
    ) {
      window.Beacon('init', '3cce4214-8caf-4aa1-a25f-05e695617f7c');
    }
  });

  router.afterEach((to) => {
    const indexable = (indexTo) => indexTo.path.includes('/documents/')
      || indexTo.path.includes('/requisitions/')
      || indexTo.path.includes('/settings/')
      || indexTo.path.includes('/insights/')
      || indexTo.name === 'editor-view';

    let recentRoutes = JSON.parse(localStorage.getItem('recentViews')) || [];
    const homeStore = useHome();
    const { updateRecentViews } = homeStore;

    // If its a route that we don't to be in their recent history we skip
    if (!indexable(to)) {
      // Still update the Pinia store even if we initially land on non-indexable page
      updateRecentViews(recentRoutes);
      return;
    }

    const newRoute = {
      meta: to.meta,
      name: to.name,
      params: to.params,
      query: to.query,
      path: to.path,
      timestamp: Date.now(),
    };

    const parentRoute = getParentRoute(to);

    if (!newRoute.meta.parentTitle) {
      newRoute.meta.parentTitle = parentRoute?.meta?.title || '';
    }

    /* If we are navigating to the same page we just update it in
    the recentRoutes to get the latest version of its parameters */
    if (to.name === recentRoutes[0]?.name) {
      recentRoutes[0] = newRoute;
    } else {
      // Prevent it from being more than 8 routes
      if (recentRoutes?.length > 7) recentRoutes.pop();
      // Add the route to the front as the most recent
      recentRoutes = [newRoute, ...recentRoutes];
    }

    localStorage.setItem('recentViews', JSON.stringify(recentRoutes));

    updateRecentViews(recentRoutes);
  });

  router.onError((error) => {
    throw error;
  });
};
