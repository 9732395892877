export const redirectExclusions = [
  'signup',
  'signup-gate',
  'provide-work-email',
  'login',
  'join-dashboard',
  'ie-deprecated',
  'verify-email',
  'sso-locked',
  'disabled-user',
  'terminated-generic',
  'terminated-hr-tech',
  'terminated-no-ats',
  'terminated-suspended',
  'trial-paused',
  'trial-expired',
  'trial-renew',
  'billing-expired',
  'account-on-hold',
  'technical-hold',
  'account-being-setup',
  'demo-request-hold',
  'maintenance',
  'create-password',
  'password-reset',
  'password-reset-success',
  'about-your-company',
  'terms-of-service',
];
